<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t("vinSearchBox.title") }}</span>
              </label>
              <v-spacer></v-spacer>
              <v-btn
                large
                width="250"
                class="
                  mr-0
                  log-out
                  create-post-btn
                  post-reply-btn
                  green
                  darken-4
                  green-gradient
                  white--text
                "
                @click="$router.push({ name: 'PreviousVinSearch' })"
              >
                {{ $t("vinSearchBox.previousVinSearch") }}
              </v-btn>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pb-4 pr-4 pl-4">
                <div>
                  <ApiErrorMessage :message="apiErrorMessage" />
                </div>
                <v-card class="pa-0 d-flex align-center mr-3" flat>
                  <v-text-field
                    v-model="vin"
                    :error-messages="vinError"
                    @blur="$v.vin.$touch()"
                    @input="errors.vin = null"
                    class="mr-3 mt-7 font-weight-black"
                    elevation="0"
                    height="60"
                    :loading="loading"
                    dense
                    :label="$t('vinSearchBox.input')"
                    outlined
                    maxlength="17"
                  ></v-text-field>
                  <v-btn
                    :disabled="!vin || ($v.vin.$invalid && $v.vin.$dirty)"
                    medium
                    light
                    min-height="60"
                    class="green darken-4 green-gradient white--text"
                    elevation="0"
                    @click="search"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-card>
                <h3 class="search-wait-vin">
                  <span v-if="loading">{{
                    $t("vinSearchBox.searchWaitMessage")
                  }}</span>
                  <span v-else>{{ $t("vinSearchBox.belowSearchInfo") }}</span>
                </h3>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "VinSearch",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      loading: false,
      apiErrorMessage: null,
      vin: null,
      errors: {
        vin: null,
      },
    };
  },
  validations: {
    vin: {
      required,
      minLength: minLength(17),
    },
  },
  computed: {
    vinError() {
      let error = null;
      if (this.$v.vin.$dirty && !this.$v.vin.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("vinSearchBox.vin"),
        });
      }
      if (this.$v.vin.$dirty && !this.$v.vin.minLength) {
        error = this.$t("bookLog.validations.vinLength");
      }
      if (this.errors && this.errors.vin) {
        error = this.errors.vin;
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      getVinSearchReport: "vinSearchReport/getVinSearchReport",
    }),

    async search() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          await this.getVinSearchReport({ vin: this.vin });
          this.$router.push({ name: "VinSearchResult" });
        } catch ({ message }) {
          this.apiErrorMessage = message;
          this.errors.vin = message;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
